import React from "react";
import Head from "next/head";
import styled from "styled-components";
import Layout from "src/layout/Layout";

const StyledNotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 0 40px;
  text-align: center;
`;

const StyledMessage = styled.h4`
  color: ${({ theme }) => theme.FULL_WHITE};
  font-size: 25px;
  font-weight: 800;
  margin: 10px 0;
`;

const StyledSubMessage = styled.div`
  width: 50%;
  color: ${({ theme }) => theme.SILVER};
  margin-bottom: 25px;
`;

const StyledImageWrapper = styled.div`
  width: 300px;
`;

const NotFound: React.FC = () => {
  return (
    <Layout>
      <Head>
        <meta name="robots" content="noindex,nofollow" />
      </Head>

      <div></div>
    </Layout>
  );
};

export default NotFound;
